import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import styles from './pages.module.css';

export default function ContactUs() {

    return (
        <section
            className={styles.contactBackImg}
            id="contact-us"
        >
            <Container>
                <Row
                    className={styles.headerBlack}
                >
                    <Col>
                        <h2>
                            Contact Us
                        </h2>
                    </Col>
                </Row>
                <Row
                    className={styles.textBlack}
                >
                    <Col
                        xs={12}
                        md={6}
                    >
                        <h3>
                            Connect With Us: Your Questions, Our Answers
                        </h3>
                        <p>
                            Matt Anderson, G.G. <br/>
                            Graduated Gemologist, G.I.A. <br/>
                            {'('}951{')'} 466-6669 <br/>
                            gsjewelrybuyers@gmail.com <br/>
                            1215 South Mission Road <br/>
                            Fallbrook, CA 92028
                        </p>
                    </Col>
                    <Col
                        xs={12}
                        md={6}
                    >
                        <div className="contactMap">
                        <iframe 
                            title="GSJL/GSJB Business Location"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3332.0157833387925!2d-117.25493308796638!3d33.370653973310404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dc7eba7b1d1abb%3A0x3345099674a818c1!2s1215%20S%20Mission%20Rd%2C%20Fallbrook%2C%20CA%2092028!5e0!3m2!1sen!2sus!4v1736125600621!5m2!1sen!2sus" 
                            width="400" 
                            height="300" 
                            style={{border: 0}}
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}